export default {
  enterprise_apps: [
    {
      module_name: "lbl_booking",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "lbl_find_and_reserve",
          url: `${hkConfigEnvs.VUE_APP_PRIVATE_BOOKING_URL}`,
          permission_object: "ENABLE_ENT_BOOKING"
        }
      ]
    },{
      module_name: "lbl_development",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "lbl_storybook",
          url: `${hkConfigEnvs.VUE_APP_STORYBOOK}`,
          permission_object: "ENABLE_ENT_SAFETYKEY"
        }
      ]
    },
    {
      module_name: "lbl_booking_management",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "lbl_search_modify",
          url: `${hkConfigEnvs.VUE_APP_SEARCH_AND_MODIFY_URL}`,
          permission_object: "ENABLE_ENT_SEARCH_MODIFY"
        }
      ]
    },
    {
      module_name: "lbl_loyalty_management",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_loyalty_management",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_LOYALTY_MANAGEMENT}`,
          permission_object: "ENABLE_ENT_LOYALTY_MANAGEMENT"
        },
        {
          name: "lbl_retailkey_loyalty_management",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_RETAILKEY_LOYALTY_MANAGEMENT}`,
          permission_object: "ENTERPRISE_RETAILKEY_LOYALTY"
        }
      ]
    },

    {
      module_name: "lbl_reports",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_enterprise_reports",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_REPORTS_URL}`,
          permission_object: "ENTERPRISE_DYNAMIC_REPORTS"
        }
      ]
    },
    {
      module_name: "lbl_trainkey",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_trainkey",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_TRAINKEY_URL}`,
          permission_object: "ENABLE_ENT_TRAINKEY"
        }
      ]
    },
    {
      module_name: "lbl_administration",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_hk_admin",
          url: `${hkConfigEnvs.VUE_APP_HK_ADMIN_URL}`,
          permission_object: "ENABLE_ENT_ADMIN_LEGACY",
          open_in_new_tab: true,
          callback: ()=>{
            localStorage.removeItem('hk-simple-redux-store')
            sessionStorage.removeItem('hk-simple-redux-store')
          }
        },
        {
          name: "lbl_user_management",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_USER_MANAGEMENT_URL}`,
          permission_object: "ENABLE_ENT_USER_MANAGEMENT"
        },
        {
          name: "lbl_paymentkey",
          url: `${hkConfigEnvs.VUE_APP_PAYMENTKEY_URL}`,
          permission_object: "ENABLE_ENT_PAYMENTKEY"
        },
        {
          name: "lbl_enterprise_configuration",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_CONFIGURATION_URL}`,
          permission_object: "ENABLE_ENTERPRISE_CONFIGURATION_APP"
        }
      ]
    },
    {
      module_name: "lbl_tenantkey",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_tenantkey_admin",
          url: `${hkConfigEnvs.VUE_APP_TENANTKEY}`,
          ng_url: `${hkConfigEnvs.VUE_APP_TENANTKEY}`,
          permission_object: "ENABLE_ENT_TENANT_KEY_RMS"
        },
        {
          name: "lbl_tenantkey_rms",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_TENANTKEY_RMS_URL}`,
          permission_object: "ENABLE_ENT_TENANT_KEY_RMS"
        },
      ]
    },
    {
      module_name: "lbl_crs",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_central_reservation_system",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_CENTRAL_RESERVATION_SYSTEM_URL}`,
          permission_object: "ENABLE_ENT_CRS"
        },
      ]
    },
    {
      module_name: "lbl_channel_content_management",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_channel_content_management",
          url: `${hkConfigEnvs.VUE_APP_CONTENT_MANAGEMENT_URL}`,
          permission_object: "ENABLE_ENT_CHANNEL_CONTENT_MANAGEMENT"
        },
      ]
    },
  ],
  property_apps: [
    {
      module_name: "lbl_operations",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_frontdesk",
          url: `${hkConfigEnvs.VUE_APP_FRONTDESK_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_NG_ENV_FRONTDESK_URL}`,
          permission_object: "ENABLE_FRONTDESK",
          params: "origin",
          property_type: "ALL"
        },{
          name: "lbl_frontdesk_ng",
          url: `${hkConfigEnvs.VUE_APP_FRONTDESK_NG_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_FRONTDESK_NG_URL}`,
          permission_object: "ENABLE_FRONTDESK_NG",
          params: "origin",
          property_type: "ALL"
        },
        {
          name: "lbl_companies_and_direct_bill",
          url: `${hkConfigEnvs.VUE_APP_COMPANIES_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_NG_ENV_COMPANIES_URL}`,
          permission_object: "ENABLE_LOCAL_COMPANIES",
          property_type: "HOTEL"
        },
        {
          name: "lbl_reports",
          url: `${hkConfigEnvs.VUE_APP_REPORTS_URL_V2}`,
          ng_url: `${hkConfigEnvs.VUE_APP_NG_ENV_REPORTS_URL_V2}`,
          permission_object: "ENABLE_PROPERTY_REPORTS",
          property_type: "ALL"
        },
        {
          name: "lbl_timekeeping",
          url: `${hkConfigEnvs.VUE_APP_TIMEKEEPING_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_TIMEKEEPING_URL}`,
          permission_object: "ENABLE_TIMEKEEPING",
          property_type: "HOTEL"
        },
        {
          name: "lbl_signature_templates",
          url: `${hkConfigEnvs.VUE_APP_SIGNATURE_TEMPLATE_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_SIGNATURE_TEMPLATE_URL}`,
          permission_object: "ENABLE_SIGNATURE_TEMPLATE",
          cookie_param: "signature_templates",
          property_type: "ALL"
        },
        {
          name: "lbl_tasks_checklists_and_engineering",
          url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_HOUSEKEEPING_AND_MAINTENANCE_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_ENTERPRISE_HOUSEKEEPING_AND_MAINTENANCE_URL}`,
          permission_object: "ENABLE_HOUSEKEEPING_MAINTENANCE",
          property_type: "ALL"
        }
      ]
    },
    {
      module_name: "lbl_analytics_and_dashboard",
      icon: `${require('@/assets/home/dashboard_blue.svg')}`,
      children: [
        {
          name: "lbl_property_dashboard",
          url: `${hkConfigEnvs.VUE_APP_PROPERTY_DASHBOARDS_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_PROPERTY_DASHBOARDS_URL}`,
          permission_object: "ENABLE_PROPERTY_DASHBOARD",
          property_type: "HOTEL"
        }
      ]
    },
    {
      module_name: "lbl_revenue_management",
      icon: `${require('@/assets/home/Revenue-management_blue.svg')}`,
      children: [
        {
          name: "lbl_rate_calendar",
          url: `${hkConfigEnvs.VUE_APP_REVENUE_MANAGEMENT_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_REVENUE_MANAGEMENT_URL}`,
          permission_object: "ENABLE_RATE_CALENDAR",
          property_type: "HOTEL"
        },
        {
          name: "lbl_rate_administration",
          url: `${hkConfigEnvs.VUE_APP_ROOM_TYPE_RATE_CODE_MANAGEMENT_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_ROOM_TYPE_RATE_CODE_MANAGEMENT_URL}`,
          permission_object: "ENABLE_RATE_CODE_ROOM_TYPE",
          property_type: "HOTEL"
        }
      ]
    },
    {
      module_name: "lbl_booking",
      icon: `${require('@/assets/home/booking_blue.svg')}`,
      children: [
        {
          name: "lbl_search_reservation",
          url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          permission_object: "ENABLE_INDIVIDUAL_BOOKING",
          route_path: "landing-page",
          params: "pc,property_id,origin",
          property_type: "HOTEL"
        },
        {
          name: "lbl_book_a_room",
          url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          permission_object: "ENABLE_INDIVIDUAL_BOOKING",
          route_path: "",
          params: "pc,property_id,origin",
          property_type: "HOTEL"
        },
        {
          name: "lbl_book_a_group",
          url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_BOOKING_URL}`,
          permission_object: "ENABLE_GROUP_BOOKING",
          route_path: "group-stay",
          params: "pc,property_id,origin",
          property_type: "HOTEL"
        }
      ]
    },
    {
      module_name: "lbl_retailkey",
      icon: `${require('@/assets/home/inventory.svg')}`,
      children: [
        {
          name: "lbl_administration",
          url: `${hkConfigEnvs.VUE_APP_POS_MENUS_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_POS_MENUS_URL}`,
          permission_object: "ENABLE_POS_MENUS",
          property_type: "ALL"
        },
        {
          name: "lbl_pos_register",
          url: `${hkConfigEnvs.VUE_APP_POS_REGISTER_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_POS_REGISTER_URL}`,
          permission_object: "ENABLE_POS_REGISTER",
          property_type: "ALL"
        },
        {
          name: "lbl_administration_v2",
          url: `${hkConfigEnvs.VUE_APP_RETAILKEY_ADMIN_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_RETAILKEY_ADMIN_URL}`,
          permission_object: "ENABLE_REATAILKEY_ADMIN_V2",
          property_type: "ALL"
        },
        {
          name: "lbl_kot",
          url: `${hkConfigEnvs.VUE_APP_KOT_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_KOT_URL}`,
          permission_object: "ENABLE_REATAILKEY_KDS",
          property_type: "ALL"
        },
      ]
    },
    {
      module_name: "lbl_eventkey",
      icon: `${require('@/assets/home/event-management.svg')}`,
      children: [
        {
          name: "lbl_administration",
          url: `${hkConfigEnvs.VUE_APP_EVENT_MANAGEMENT_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_EVENT_MANAGEMENT_URL}`,
          permission_object: "ENABLE_EVENT_MANAGEMENT_ADMIN",
          cookie_param: "administration",
          property_type: "ALL"
        },
        {
          name: "lbl_manage_events",
          url: `${hkConfigEnvs.VUE_APP_EVENT_MANAGEMENT_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_EVENT_MANAGEMENT_URL}`,
          permission_object: "ENABLE_EVENT_MANAGEMENT",
          cookie_param: "manage_events",
          property_type: "ALL"
        }
      ]
    },
    {
      module_name: "lbl_administration",
      icon: `${require('@/assets/home/Administration_blue.svg')}`,
      children: [
        {
          name: "lbl_property_settings",
          url: `${hkConfigEnvs.VUE_APP_PROPERTY_MANAGEMENT_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_NG_ENV_PROPERTY_MANAGEMENT_URL}`,
          permission_object: "ENABLE_PROPERTY_MANAGEMENT",
          property_type: "ALL"
        },
        {
          name: "lbl_user_management",
          url: `${hkConfigEnvs.VUE_APP_USER_MANAGEMENT_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_NG_ENV_USER_MANAGEMENT_URL}`,
          permission_object: "ENABLE_USER_MANAGEMENT",
          property_type: "ALL"
        },
        {
          name: "lbl_advanced_batch_actions",
          url: `${hkConfigEnvs.VUE_APP_ADVANCED_BATCH_ACTIONS}`,
          ng_url: `${hkConfigEnvs.VUE_APP_ADVANCED_BATCH_ACTIONS}`,
          permission_object: "RESERVATIONS_ADVANCE_SEARCH",
          property_type: "HOTEL"
        },
        {
          name: "lbl_guestkey_admin",
          url: `${hkConfigEnvs.VUE_APP_GUESTKEY}`,
          ng_url: `${hkConfigEnvs.VUE_APP_GUESTKEY}`,
          permission_object: "ENABLE_GUEST_KEY",
          property_type: "HOTEL"
        }
      ]
    },
    {
      module_name: "lbl_activitykey",
      icon: `${require('@/assets/home/event-management.svg')}`,
      children: [
        {
          name: "lbl_administration",
          url: `${hkConfigEnvs.VUE_APP_ACTIVITIES_ADMIN_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_ACTIVITIES_ADMIN_URL}`,
          permission_object: "ENABLE_ACTIVITIES_ADMIN_APP",
          cookie_param: "activities",
          property_type: "ALL"
        },
        {
          name: "lbl_manage_activities",
          url: `${hkConfigEnvs.VUE_APP_ACTIVITIES_URL}`,
          ng_url: `${hkConfigEnvs.VUE_APP_ACTIVITIES_URL}`,
          permission_object: "ENABLE_ACTIVITIES_APP",
          cookie_param: "activities",
          property_type: "ALL"
        },

      ]
    },

  ]
}
